export type DropdownMenuPositionAlign = "start" | "end";
export type DropdownMenuPositionSide = "top" | "bottom" | "left" | "right";

export type DropdownPosition =
  `${DropdownMenuPositionSide}-${DropdownMenuPositionAlign}`;

export const useDropdownPosition = (
  position: DropdownPosition,
  options?: { offset?: boolean },
) => {
  return {
    class: computed(() => {
      const bottom =
        (options?.offset ?? true)
          ? "top-[calc(100%+10px)]"
          : "top-[calc(100%-1px)]";
      const top =
        (options?.offset ?? true)
          ? "bottom-[calc(100%+10px)]"
          : "bottom-[calc(100%-1px)]";

      const right =
        (options?.offset ?? true)
          ? "left-[calc(100%+10px)]"
          : "left-[calc(100%-1px)]";

      const left =
        (options?.offset ?? true)
          ? "right-[calc(100%+10px)]"
          : "right-[calc(100%-1px)]";

      if (position === "top-start") return [top, "left-0"];
      if (position === "top-end") return [top, "right-0"];
      if (position === "bottom-start") return [bottom, "left-0"];
      if (position === "bottom-end") return [bottom, "right-0"];
      if (position === "right-end") return ["bottom-0", right];
      if (position === "left-end") return ["bottom-0", left];
    }),
  };
};
